<script setup>
import { ref, getCurrentInstance, onMounted, reactive } from "vue"
import { ElMessage, ElNotification } from 'element-plus'

const { appContext } = getCurrentInstance()
let searchInfo = ref({
  invoice_header: ""
})

let datainfo = {
  current: 1,
  pageSize: 10,
  page: 1
}

const handleReset = () => {
  searchInfo.value.invoice_header = ""
  datainfo.invoice_header = ""
  queryInvoiceRecords()
}

const handleSearch = () => {
  if (searchInfo.value.invoice_header != "") {
    datainfo.invoice_header = searchInfo.value.invoice_header
    queryInvoiceRecords(datainfo)
  }
}

// 表格数据
let isloading = ref(true)
let tableData = ref()
let pagination = reactive({
  count: 0,
  currentPage: 0,
  perPage: 0,
  total: 0,
  totalPages: 0
})

const pageChange = (page) => {
  datainfo.page = page
  queryInvoiceRecords(datainfo)
}

const tab = [
  { name: '待审核', status: 10, type: 'warning' },
  { name: '风控审核通过', status: 20, type: 'volcano' },
  { name: '风控审核不通过', status: 30, type: 'danger' },
  { name: '开票中', status: 40, type: 'warning' },
  { name: '拒绝开票', status: 50, type: 'danger' },
  { name: '开票完成', status: 60, type: 'success' },
  { name: '撤销申请', status: 70, type: 'danger' },
  { name: '已红冲', status: 80, type: 'info' },
];

let chengeStatusData = reactive({
  id: "",
  status: ""
})

const handleCommand = (command) => {
  chengeStatusData.status = command
  ChangeStatus(chengeStatusData)
}

const handleChangeStatus = (row) => {
  chengeStatusData.id = row.id
}

const ChangeStatus = (data) => {
  console.log(data);
  appContext.config.globalProperties.$api.invoice.modify(data).then(res => {
    if (res.status == 200) {
      ElNotification({
        title: '修改成功',
        message: '数据修改成功！',
        type: 'success',
      })
      queryInvoiceRecords()
    } else {
      ElNotification({
        title: '修改失败',
        message: res,
        type: 'error',
      })
    }
  })
}

// 查询发票记录
const queryInvoiceRecords = (data) => {
  isloading.value = true
  appContext.config.globalProperties.$api.invoice.query(data || datainfo).then(res => {
    if (res.status == 200) {
      tableData.value = res.data.data
      pagination.count = res.data.pagination.count
      pagination.perPage = res.data.pagination.perPage
      pagination.currentPage = res.data.pagination.currentPage
      pagination.total = res.data.pagination.total
      pagination.totalPages = res.data.pagination.totalPages
      isloading.value = false
    } else {
      ElMessage.error('发票查询失败,请稍后重试.')
      isloading.value = false
      console.log(res);
    }
  })
}

onMounted(() => {
  queryInvoiceRecords()
})

</script>


<template>
  <el-card shadow="never">
    <el-form :inline="true" :model="searchInfo" label-width="100px" label-position="right">
      <div class="top-search">
        <el-form-item label="发票抬头：">
          <el-input v-model="searchInfo.invoice_header" placeholder="请输入发票抬头" />
        </el-form-item>
        <div>
          <el-button @click="handleReset()">重置</el-button>
          <el-button type="primary" @click="handleSearch()">查询</el-button>
        </div>
      </div>
    </el-form>
  </el-card>

  <div class="table-data">
    <el-table :data="tableData" style="width: 100%" v-loading="isloading" size="small">
      <el-table-column label="序号" width="80" type="index" align="center">
        <template #default="scope">
          {{ (pagination.currentPage -1) * pagination.perPage +scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="invoice_header" label="发票抬头" width="260px" />
      <el-table-column prop="taxpayer_identification_number" label="纳税人识别号" width="260px" />
      <el-table-column prop="invoice_type" label="发票类型">
        <template #default="scope">
          <el-tag type="warning" v-if="scope.row.invoice_type==1">专用增值税发票</el-tag>
          <el-tag type="success" v-if="scope.row.invoice_type==2">普通增值税发票</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoice_money" label="开票金额" />
      <el-table-column prop="basic_account" label="开户账户" />
      <el-table-column prop="bank_name" label="开户行" />
      <el-table-column prop="addressee_phone" label="收件人手机号">
        <template #default="scope">
          <p v-if="scope.row.addressee_phone==null">-</p>
          <p v-else>{{scope.row.addressee_phone}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-dropdown @command="handleCommand">
            <el-tag v-if="scope.row.status==10" @click="handleChangeStatus(scope.row)" type="warning">待审核</el-tag>
            <el-tag v-if="scope.row.status==20" @click="handleChangeStatus(scope.row)" type="success">风控审核通过</el-tag>
            <el-tag v-if="scope.row.status==30" @click="handleChangeStatus(scope.row)" type="danger">风控审核不通过</el-tag>
            <el-tag v-if="scope.row.status==40" @click="handleChangeStatus(scope.row)" type="warning">开票中</el-tag>
            <el-tag v-if="scope.row.status==50" @click="handleChangeStatus(scope.row)" type="danger">拒绝开票</el-tag>
            <el-tag v-if="scope.row.status==60" @click="handleChangeStatus(scope.row)" type="success">开票完成</el-tag>
            <el-tag v-if="scope.row.status==70" @click="handleChangeStatus(scope.row)" type="danger">撤销申请</el-tag>
            <el-tag v-if="scope.row.status==80" @click="handleChangeStatus(scope.row)" type="info">已红冲</el-tag>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="list in tab" :key="list.index" :command=list.status>{{list.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="空数据" :image-size="200" />
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :total=pagination.total @current-change="pageChange" :page-size=pagination.perPage :current-page=pagination.currentPage />
    </div>
  </div>
</template>

<style lang="less" scoped>
.top-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-data {
  margin-top: 20px;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
